<!-- 产品与服务2 云上横河系统 -->
<template>
    <div class="product2">
        <banner-bar></banner-bar>

        <div class="boxes1" id="jumpPoint">
            <div class="pub_title_area">产品名称</div>
            <div class="name">云上横河系统</div>
            <img src="./../../assets/Product2/img1.png" alt="">
        </div>

        <div class="boxes2">
            <div class="pub_title_area">产品详情</div>
            <div class="content_area" v-for="(info,index) in infoes" :key="index">
                <div class="left">
                    <div class="title">{{info.title}}</div>
                    <div class="content">{{info.content}}</div>
                </div>
                <img :src="info.imgUrl" alt="">
            </div>
        </div>

        <div class="boxes3">
            <div class="title">功能预览</div>
            <div class="box_content">
                <img class="img4" src="./../../assets/Product2/img4.png" alt="">
                <div class="center_area">
                    <div class="per_tip" v-for="(item,index) in details" :key="index">
                        <div class="per_title">{{item.title}}</div>
                        <div class="per_content">{{item.content}}</div>
                    </div>
                </div>
                <img class="img5" src="./../../assets/Product2/img5.png" alt="">
            </div>
        </div>

        <div class="boxes4">
            <div class="title">产品意义</div>
            <div class="per_tip" v-for="(item,index) in significance" :key="index">
                <div class="per_title">{{item.title}}</div>
                <div class="per_content">{{item.content}}</div>
            </div>
            <img class="img6" src="./../../assets/Product2/img6.png" alt="">
            <img class="img7" src="./../../assets/Product2/img7.png" alt="">
        </div>
    </div>
</template>
<script>
import bannerBar from './../../components/bannerBar/bannerBar.vue'
export default{
    name:'Product2',
    components:{
        bannerBar
    },
    data(){
        return{
            infoes:[
                {
                    title:'产品背景',
                    content:'秋高气爽，在瓯江畔的乐清市磐石镇横河村，正是风吹稻浪美如画的景象。在田边，一个印有二维码的崭新立牌引人注目。扫一扫码，进入“云上横河”系统，屏幕上出现了该村党总支书记汤艳的面孔。走在欣欣向荣的村庄里，时不时能看到这样的立牌。原来，这是云谷使用数字化手段开发的球全新的一套系统。',
                    imgUrl:require('./../../assets/Product2/img2.png')
                },
                {
                    title:'产品简介',
                    content:'方便用户为了景点能更加效率的了解情况，能对整个村之间的能更快了解项目建设情况。整个云上横河“码上说”就这么诞生了，云谷特意为此开发的系统。根据首页的地图了解大致的情况，在每个景点之间设置了二维码，更快流通各自的情况。',
                    imgUrl:require('./../../assets/Product2/img3.png')
                },
            ],
            details:[
                {
                    title:'1.手绘地图',
                    content:'首页的手绘地图完美展现了个横河村的整个大概面貌，对6个景点也标注了大概的位置方便用户不容易迷路。',
                },
                {
                    title:'2.功能按钮',
                    content:'利用了景点的重要性，把相关景点做成按钮提示，点击能直接跳到对应的景点介绍。进一步提高了效率所在。',
                },
                {
                    title:'3.详情页',
                    content:'每个景点的资料和信息都会收录到这个页面上能让用户一览无余，简洁方便好操作。',
                },
            ],
            significance:[
                {
                    title:'1.智慧乡村',
                    content:'智慧乡村，通过互联网技术、信息通信技术实现乡村的景点、住宿、采摘和其他项目的在线展现、网上预订、支付、电子认证及统一管理。',
                },
                {
                    title:'2.物联网',
                    content:'指通信网和互联网的拓展应用和网络延伸，它利用感知技术与智能装置对物理世界进行感知识别，通过网络传输互联，进行计算、处理和知识挖掘，实现人与物、物与物信息交互和无缝链接，达到对物理世界实时控制、精确管理和科学决策目的。',
                }
            ]
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import './Product2.scss'
</style>


